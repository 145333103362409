.hdrTop {
  border-bottom: 1px solid $color_primary;
}

@media (max-width: 767px) {
  .hdrInfo {
    font-size: 10px;
    text-align: center !important;
  }
}

#hdrLogo img {
  max-width: 169px;
  width: 100%;
}

@media (max-width: 767px) {
  #hdrLogo img {
    max-width: 101px;
  }
}

.iconNav--image [class*="hasChildren"]>a:after {
  border-color: $color_primary;
}

.iconNav__cart .cartAmount {
  background: $color_text;
}

.megaMenu__title {
  font-weight: 600;
}

@media (max-width: 767px) {
  .drawer__nav {
    >li {
      border-top: 1px solid $color_primary;
      font-weight: 400;

      &.hasChildren--click a {
        font-family: $heading_font_family;
        font-weight: 400;

        &.open {
          border-bottom: 1px solid $color_primary;
        }
      }

      &:last-child {
        border-bottom: 1px solid $color_primary;
      }

      &.hasChildren--click li {
        background: #F2F2F2;
      }
    }
  }

  .drawer__accountText {
    background: transparent;
  }

  a.drawer__close {
    background: #ECECEC;
    color: $color_text;
    font-weight: 500;
  }
}

.h2B:after {
  background: $color_primary;
}

.multiGrid {
  &::-webkit-scrollbar-thumb {
    background: #333333;
  }

  @media (max-width: 767px) {
    gap: 15px 0;
  }
}

.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.slick-dots {
  bottom: 20px;

  li {
    margin: 0 7px;

    button:before {
      width: 10px;
      height: 10px;
    }
  }
}

.mainSliderSec {
  margin-bottom: 0;
}

.sec-pickup {
  .button {
    background: transparent;
    color: $color_primary_text;

  }

  @media (max-width: 767px) {
    .textBnr__box h3 {
      font-size: 20px;
    }

    .button {
      font-size: 16px;
    }
  }
}

@media (max-width: 767px) {
  .all-link {
    text-align: center;
    margin-bottom: 20px;
  }
}

.sec-news {
  padding: 18px 0;

  .newsList--typeC .newsList__head h2 {
    color: $color_text;
    background: transparent;

    i {
      color: $color_primary;
      display: inline-block;
    }
  }

  .newsList li h3 {
    font-weight: 400;
  }
}

.h2-button-typeA,
.h2-button-typeB {
  color: #9A9A9A !important;
  letter-spacing: 0.2rem;

  &::after {
    border-color: #9A9A9A;
  }
}

.textBnr__box.tb--narrow h3 {
  color: #fff;
  font-family: 'Montserrat', sans-serif;
}

.textBnr__box .button {
  border: 1px solid #fff;
  width: 200px;
}

.itemList--ranking.ranking--typeF .itemWrap:before {
  font-family: 'Montserrat', sans-serif;
}

.itemList--ranking.ranking--typeF .itemWrap:after {
  background: rgba(27, 27, 27, 0.3);
}

.itemList--ranking .itemList__unit:nth-child(n + 4) .itemWrap:before,
.itemList--ranking .itemList__unit:nth-child(n + 4) .itemWrap:after {
  display: none;
}

.sec-category {
  .row.brand-name {
    padding-bottom: 40px;
    position: relative;

    .h2-button_area {
      align-items: flex-end;
      position: relative;

      .h2-button-typeB {
        border-color: #9A9A9A;
        padding: 5px 25px 5px 10px;

        &.arrow::after {
          right: 5px;
        }
      }
    }

    @media (max-width: 767px) {
      text-align: center;
      position: relative;

      img {
        max-width: 157px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .cateList {
    margin-bottom: 60px;

    a {
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;

      &::after {
        border-width: 1px;
      }
    }
  }
}

.tab--typeB .tabSwitch li {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;

  &.active {
    color: $color_text;
    border-bottom: 1px solid $color_text;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border: 5px solid transparent;
      border-top: 5px solid $color_text;
      background: transparent;
    }
  }

  &:last-child::after {
    content: none;
  }

  @media (max-width: 767px) {
    font-size: 18px !important;
    width: 50%;
  }
}

.sec-information {
  p {
    text-align: center;
  }
}

.fNav__logo img {
  max-width: 82px
}

.snsNav li {
  margin-right: 10px;
}

.copyright {
  border-top: 1px solid $color_primary;
}

.fNav--typeC .fNav__list a::after,
.fNav--typeC .fNav__list a.open::after {
  border-color: #1B1B1B !important;
}

@media (min-width: 992px) {
  .fNav--typeC .snsNav {
    padding: 0 20px;
  }

  .fNav--typeC .fNav__list {
    padding: 6px 0;
  }

  .fNav--typeC .fNav__list:last-of-type {
    font-weight: 700;
  }

  .fNav--typeC .fNav__list li {
    padding: 0 5px;
  }

}

@media (max-width: 767px) {
  .fNav--typeC .fNav__list ul.open li:last-child a {
    border-bottom: 1px solid #dfdfdf !important;
  }
}

